import React from "react";
import { useLastRealisation } from "../../src/_services/realisation-service";

const NewsSection = () => {
  const { lastRealisation, error, isLoading } = useLastRealisation();

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <div>chargement en cours</div>;
  }

  if (!!lastRealisation) {
    const { id, name, cover_picture_url, catch_line } =
      lastRealisation.data.attributes;
    return (
      <div className="grid grid-cols-1 lg:grid-cols-4 sm:mx-auto mx-4 sm:max-w-7xl bg-white sm:mt-16 mt-10 mb-16">
        <div className="col-span-1 lg:col-span-2 border-l-2 border-golden-500 pl-8">
          <div className="flex flex-col sm:text-center">
            <p className="font-birthstone sm:text-56 text-32 text-rouge z-10">
              Notre
            </p>
            <p className="font-fraunces sm:text-56 text-32 text-black -mt-4 z-10">
              Actualités
            </p>
          </div>
          <h2
            className={
              "py-2 font-helvetica text-black font-bold text-base lg:text-20 sm:text-justify sm:mr-12 lg:mr-32 first-letter:capitalize"
            }
          >
            {name}
          </h2>
          <p className="py-2 font-helvetica text-black text-base lg:text-20 sm:text-justify sm:mr-12 lg:mr-32">
            {catch_line}
          </p>

          <a
            className="button-translation flex font-helvetica text-20 text-white bg-rouge mt-10 py-3 px-6 rounded-md shadow items-center"
            href={`/nos-actualites/${id}`}
          >
            Découvrir
          </a>
        </div>
        <div className="col-span-1 lg:col-span-2 items-end sm:flex hidden">
          <div className="aspect-w-16 aspect-h-9 w-full ">
            <div
              className="imgs img-radius rounded-lg bg-cover bg-center"
              style={{ backgroundImage: `url(${cover_picture_url})` }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NewsSection;
