import ReactOnRails from "react-on-rails";
import "./application.css";

// page
import Homepage from "../bundles/pages/Homepage";
import Presentation from "../bundles/pages/Presentation";
import Categories from "../bundles/pages/Categories";
import News from "../bundles/pages/News";
import NewItem from "../bundles/pages/NewItem";
import Contact from "../bundles/pages/Contact";
import CategoryDetails from "../bundles/pages/CategoryDetails";

ReactOnRails.register({
  Homepage,
  Presentation,
  Categories,
  NewItem,
  News,
  Contact,
  CategoryDetails,
});
