import React, { useEffect, useState } from "react";

export const useOffsetTop = () => {
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    setOffsetTop(document.getElementById("navbar")?.offsetHeight + 32);
    window.onresize = () => {
      setOffsetTop(document.getElementById("navbar")?.offsetHeight + 32);
    };
  }, []);

  return offsetTop;
};
