import React from "react";
import CategoryCard from "./CategorieCard";
import { uniqueId } from "lodash";

const SectionProduct = ({ categories }) => {
  return (
    <div className="w-full">
      <div className="sm:w-vw-5/6 sm:mx-auto mx-4 max-w-screen-2xl">
        <div className="flex justify-between mb-9 xl:flex-row flex-col">
          <div className="xl:w-vw-1/4 flex sm:justify-center">
            <div className="flex flex-col sm:text-center">
              <p className="font-birthstone sm:text-56 text-32 text-rouge z-10">
                Notre
              </p>
              <p className="font-fraunces sm:text-56 text-32 text-black -mt-4 z-10">
                Charcuterie
              </p>
            </div>
          </div>
          <div className="xl:w-paragraph z-10">
            <p className="font-helvetica 2xl:text-20 text-16 text-black text-justify">
              Fondée en 1960, la charcuterie Vasseur est une entreprise
              familiale qui se transmet de génération en génération. Nous
              produisons des jambons, saucissons, pâtés et autres spéciliatés
              polonaises. Nous mettons un point d'honneur à respecter les normes
              les plus élevées en matière de qualité et d'hygiène.
            </p>
          </div>
        </div>
      </div>

      <div className="flex relative justify-between bg-skin-500 mt-40 px-4 w-full">
        <div
          className={
            "sm:w-vw-5/6 sm:mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 -mt-32 gap-8 max-w-full mx-auto"
          }
        >
          {categories.data.map((category) => {
            return (
              <CategoryCard key={uniqueId("category__")} category={category} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionProduct;
