import React, { useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import styles from "./styles.modules.css";
import cx from "classnames";

const SearchInput = () => {
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = query.trim();
    if (value && value.length >= 3) {
      window.location.assign(`/nos-gammes-produits?recherche=${value}`);
    } else if (value.length === 0) {
      window.location.assign("/nos-gammes-produits");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(new URL(window.location).search);
    const query = params.get("recherche");
    setQuery(query);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center pb-[1px] w-full mt-2 sm:mt-0 border-b border-golden-500">
        <span className="mx-2 hidden sm:block">
          <SearchIcon className="h-[24px] w-[24px] text-golden-500" />
        </span>
        <input
          onChange={handleChange}
          className={cx(
            styles.search_input,
            "h-8 font-fraunces pl-0 sm:pl-2 placeholder:text-gray-500 placeholder:font-fraunces sm:placeholder:text-transparent"
          )}
          placeholder="Rechercher"
          value={query}
          type="text"
        />
        <button
          type="submit"
          className="hover:text-black font-fraunces text-gray-500 mx-2"
        >
          <span className="hidden sm:block">Rechercher</span>
          <span className="block sm:hidden">
            <SearchIcon className="h-[24px] w-[24px] text-golden-500" />
          </span>
        </button>
      </div>
    </form>
  );
};

export default SearchInput;
