import React, { useState } from "react";

const CategoriesContext = React.createContext();

export const CategoriesContextProvider = ({ children }) => {
  const [filteredCategories, setFilteredCategories] = useState(null);

  const updateFilteredCategories = (categories) => {
    setFilteredCategories(categories);
  };

  return (
    <CategoriesContext.Provider
      value={{ filteredCategories, updateFilteredCategories }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContext;
