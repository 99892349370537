import React, { useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
// import Header from "../components/Header";
import _ from "lodash";
import { useOffsetTop } from "../../hooks/useOffsetTop";
import "../../../packs/application.css";
import { CategoriesContextProvider } from "../../contexts/CategoriesContext";

const GenericLayout = ({ children, categories }) => {
  const offsetTop = useOffsetTop();

  return (
    <CategoriesContextProvider>
      <NavBar />
      <main style={{ paddingTop: `${offsetTop}px` }}>{children}</main>
      <Footer categories={categories} />
    </CategoriesContextProvider>
  );
};

export default GenericLayout;
