import Api, { API_V1_BASE_URL } from "../helpers/api";

export const createContact = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/contacts`,
    { contact: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => {
      callback && callback(null, res);
    })
    .catch((e) => {
      callback && callback(e, null);
    });
};
