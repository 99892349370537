import React, { useState } from "react";
import GenericLayout from "../components/layout/GenericLayout";
import contact from "../../../assets/images/contact.jpg";
import { useForm } from "react-hook-form";
import { createContact } from "../services/contact-service";
import styles from "../styles/contact.module.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

const contactSchema = Yup.object().shape({
  firstname: Yup.string().required("Champ obligatoire."),
  lastname: Yup.string().required("Champ obligatoire."),
  email: Yup.string()
    .email("Adresse e-mail invalide.")
    .required("Champ obligatoire."),
  phone_number: Yup.string(),
  company: Yup.string().required("Champ obligatoire."),
  message: Yup.string(),
  how_did_you_know: Yup.string().required("Champ obligatoire."),
  consent: Yup.boolean().isTrue("Champ obligatoire."),
});

const Contact = ({ categories }) => {
  const [isNotSent, setIsNotSent] = useState(false);
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const handleSubmit = (values, actions) => {
    setDisableBtnSubmit(true);
    createContact(values, (err) => {
      setIsNotSent(err);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      setDisableBtnSubmit(false);
      actions.resetForm();
    });
  };

  return (
    <GenericLayout categories={categories}>
      <div className="flex relative sm:flex-row flex-col-reverse items-start justify-between xl:ml-48 xl:mr-0 sm:ml-4 mx-4 my-10">
        <div className="w-full xl:w-1/2">
          <div className={styles.title_container}>
            <h2 className="mb-2 font-fraunces sm:text-5xl text-4xl text-left">
              Travaillons ensemble
            </h2>
            <p className="font-grotesque 2xl:text-24 text-16 text-left">
              Nous aimerions être en contat avec vous ! Utilisez ce formulaire
              pour communiquer avec nous sans vous déplacer, ou envoyez nous un
              e-email nous vous répondrons le plus vite possible :)
            </p>
          </div>
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              phone_number: "",
              company: "",
              message: "",
              how_did_you_know: "",
              consent: false,
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid }) => (
              <Form className="space-y-8">
                <div className="xl:pt-16 md:pt-8">
                  {!isNotSent && isNotSent !== false ? (
                    <div className="w-full p-4 rounded-lg bg-green-100 text-sm sm:text-base mt-6">
                      Votre mail est bien envoyé.
                    </div>
                  ) : (
                    isNotSent && (
                      <div className="w-full p-4 rounded-lg bg-red-100 text-sm sm:text-base mt-6">
                        Echec d'envoi, veuillez réessayer plus tard.
                      </div>
                    )
                  )}
                  <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Nom*
                      </label>
                      <div className="mt-1">
                        <Field
                          name="lastname"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.lastname && touched.lastname,
                          })}
                        />
                        {errors.lastname && touched.lastname ? (
                          <div className={styles.error_label}>
                            {errors.lastname}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Prénom*
                      </label>
                      <div className="mt-1">
                        <Field
                          name="firstname"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.firstname && touched.firstname,
                          })}
                        />
                        {errors.firstname && touched.firstname ? (
                          <div className={styles.error_label}>
                            {errors.firstname}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        E-mail*
                      </label>
                      <div className="mt-1">
                        <Field
                          name="email"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.email && touched.email,
                          })}
                        />
                        {errors.email && touched.email ? (
                          <div className={styles.error_label}>
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="phone"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Téléphone
                      </label>
                      <div className="mt-1">
                        <Field
                          name="phone_number"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.phone_number && touched.phone_number,
                          })}
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <div className={styles.error_label}>
                            {errors.phone_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="nom-entreprise"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Nom de l'entreprise*
                      </label>
                      <div className="mt-1">
                        <Field
                          name="company"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.company && touched.company,
                          })}
                        />
                        {errors.company && touched.company ? (
                          <div className={styles.error_label}>
                            {errors.company}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="help"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Comment pouvons-nous vous aider ?
                      </label>
                      <div className="mt-1">
                        <Field
                          as="textarea"
                          name="message"
                          placeholder="500 caractères"
                          className="block w-full border rounded-md focus:ring-2 focus:ring-golden-700 outline-none border-golden-500 font-helvetica bg-white "
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="aboutus"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Comment avez vous entendu parler de nous ?
                      </label>
                      <div className="mt-1">
                        <Field
                          as="select"
                          name="how_did_you_know"
                          className={classNames(styles.input, {
                            "focus:!ring-red-500 !border-red-500":
                              errors.how_did_you_know &&
                              touched.how_did_you_know,
                          })}
                        >
                          <option
                            disabled
                            value={""}
                            className="text-skin sm:text-20 text-12"
                          >
                            Selectionnez dans la liste
                          </option>
                          <option className="text-skin text-16" value={"salon"}>
                            Salon
                          </option>
                          <option
                            className="text-skin text-16"
                            value={"bouche_a_oreille"}
                          >
                            Bouche à oreille
                          </option>
                          <option
                            className="text-skin text-16"
                            value={"publicite"}
                          >
                            Publicité
                          </option>
                        </Field>
                        {errors.how_did_you_know && touched.how_did_you_know ? (
                          <div className={styles.error_label}>
                            {errors.how_did_you_know}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-6 mt-4">
                      <label className="sm:text-16 text-14 text-skin flex items-center">
                        <Field
                          type="checkbox"
                          name="consent"
                          className="focus:ring-skin h-4 w-4 text-skin border-skin rounded"
                        />
                        <span className="ml-2 text-sm">
                          Oui, je donne mon consentement au traitement des
                          données. *
                        </span>
                      </label>
                      {errors.consent && touched.consent ? (
                        <div className={styles.error_label}>
                          {errors.consent}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <button
                      disabled={!isValid || disableBtnSubmit}
                      type="submit"
                      className={classNames(
                        "flex justify-center mt-6 bg-rouge hover:bg-red-600 py-3 sm:px-14 px-10 border border-transparent shadow-sm sm:text-20 text-16 font-medium rounded-md text-white font-helvetica",
                        { "!bg-red-200": !isValid }
                      )}
                    >
                      {disableBtnSubmit ? "Envoi en cours..." : "Envoyer"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="hidden xl:block w-full xl:w-1/2 ml-8">
          <div
            className="lg:flex aspect-ratio-item hidden  aspect-w-1 aspect-h-1 bg-center bg-cover bg-no-repeat rounded-l-lg h-screen"
            style={{ backgroundImage: `url(${contact})` }}
          />
        </div>
      </div>
    </GenericLayout>
  );
};
export default Contact;
