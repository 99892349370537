import React, { useMemo } from "react";
import GenericLayout from "../components/layout/GenericLayout";
import { uniqueId } from "lodash";
import { isEven } from "../helpers/compare";

// export const isRegular = (thumb_type) => {
//   return thumb_type === "regular";
// };

// export const isLarge = (thumb_type) => {
//   return thumb_type === "large";
// };

function ProductLine({ product, included }) {
  const { name, cover_picture_url, description } = product.attributes;

  const data = useMemo(() => {
    return product?.relationships?.materials?.data || [];
  }, [product]);

  const materials = (included || [])
    .filter((item) => (data || []).map((i) => i.id).includes(item.id))
    .map((item) => item.attributes);

  return (
    <div className="flex lg:flex-row flex-col-reverse xl:space-x-5">
      <div className="flex xl:w-vw-2/5 xl:justify-end">
        <div className="flex flex-col xl:w-vw-1/3 items-start justify-center">
          <div className="xl:mt-0 mt-4">
            <h1 className="mr-4 font-fraunces font-bold sm:text-40 text-24 text-black uppercase">
              {name}
            </h1>
          </div>
          <div className="font-helvetica text-16 text-black ">
            <p className="underline">Conditionnements</p>
            <ul className={"list-disc list-inside"}>
              {materials.map(({ name }) => {
                return <li key={uniqueId("packing__")}>{name}</li>;
              })}
            </ul>
          </div>
          <div className="font-helvetica text-16 text-black mt-4">
            <p className="underline">Caractéristiques</p>
            <ul>
              <li className="italic font-medium">{description}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="xl:w-vw-2/5 w-full xl:mx-auto">
        <div
          className="imgs aspect-w-8 aspect-h-5 rounded-lg bg-cover bg-center"
          style={{
            backgroundImage: `url(${cover_picture_url})`,
          }}
        />
      </div>
    </div>
  );
}

function ProductLineReverse({ product, included }) {
  const { name, cover_picture_url, description } = product.attributes;

  const data = useMemo(() => {
    return product?.relationships?.materials?.data || [];
  }, [product]);

  const materials = (included || [])
    .filter((item) => (data || []).map((i) => i.id).includes(item.id))
    .map((item) => item.attributes);

  return (
    <div className="flex lg:flex-row-reverse flex-col-reverse xl:space-x-5">
      <div className="flex xl:w-vw-2/5 xl:justify-end">
        <div className="flex flex-col xl:w-vw-1/3 items-start justify-center">
          <div className="xl:mt-0 mt-4">
            <h1 className="mr-4 font-fraunces font-bold sm:text-40 text-24 text-black uppercase">
              {name}
            </h1>
          </div>
          <div className="font-helvetica text-16 text-black">
            <p className="underline">Conditionnements</p>
            <ul className={"list-disc list-inside"}>
              {materials.map(({ name }) => {
                return <li key={uniqueId("packing__")}>{name}</li>;
              })}
            </ul>
          </div>
          <div className="font-helvetica text-16 text-black">
            <p className="underline">Caractéristiques</p>
            <ul>
              <li className="italic font-medium">{description}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="xl:w-vw-2/5 w-full xl:mx-auto md:mr-4">
        <div
          className="imgs aspect-w-8 aspect-h-5 rounded-lg bg-cover"
          style={{
            backgroundImage: `url(${cover_picture_url})`,
          }}
        />
      </div>
    </div>
  );
}

const CategoryDetails = ({ products, categories, category }) => {
  return (
    <GenericLayout categories={categories}>
      <div className="col-span-1 flex sm:justify-center ">
        {category && (
          <div className="flex flex-col sm:text-center">
            <p className="font-fraunces sm:text-56 text-32 -mt-2 text-black z-10">
              {category.name}
            </p>
          </div>
        )}
      </div>
      <div className="lg:w-vw-4/5 lg:mx-auto mx-4 sm:mt-20 xl:space-y-20 space-y-8 xl:mb-32">
        {products.data.map((product, index) => {
          if (isEven(index)) {
            return (
              <ProductLine
                key={uniqueId("product_line--")}
                product={product}
                included={products?.included || []}
              />
            );
          }
          return (
            <ProductLineReverse
              key={uniqueId("product_line--")}
              product={product}
              included={products?.included || []}
            />
          );
        })}
      </div>
    </GenericLayout>
  );
};

export default CategoryDetails;
