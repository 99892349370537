import React from "react";
import slugify from "react-slugify";

const ClickableItem = ({ category, children }) => {
  return (
    <>
      {category?.relationships?.products?.data?.length ? (
        <a
          className="col-span-1 w-full mb-6 cursor-pointer"
          href={`/nos-gammes-produits/${category.id}/${slugify(
            category.attributes.name
          )}`}
        >
          {children}
        </a>
      ) : (
        <div className="col-span-1  w-full mb-6">{children}</div>
      )}
    </>
  );
};

const CategoryCard = ({ category }) => {
  return (
    <ClickableItem category={category}>
      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75">
        <img
          src={category.attributes.cover_picture_url}
          alt={category.attributes.name}
          className="h-full w-full object-cover object-center lg:h-full lg:w-full"
        />
      </div>
      <div className="text-center xl:mt-4 mt-2">
        <p className="font-fraunces xl:text-24 2xl:text-32 text-18 text-black font-semibold">
          {category.attributes.name}
        </p>
        <p className="font-helvetica text-16 text-black xl:mx-21">
          {category.attributes.description}{" "}
        </p>
      </div>
    </ClickableItem>
  );
};

export default CategoryCard;
