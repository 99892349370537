import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const useRealisation = (id) => {
  const { data, error } = useSWR(
    id ? `${API_V1_BASE_URL}/realisations/${id}` : null,
    getFetcher
  );
  return {
    realisation: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useRealisations = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/realisations`, getFetcher);
  return {
    realisations: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useRealisationsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_realisations_options`,
    getFetcher
  );
  return {
    options: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const createRealisation = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/realisations`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const updateRealisation = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/realisations/${id}`,
    { ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};

export const deleteRealisation = (id, callback) => {
  Api.delete(`/api/v1/realisations/${id}`).then(callback);
};

export const useLastRealisation = () => {
  const { data, error } = useSWR("/api/v1/realisations/last", getFetcher);

  return {
    lastRealisation: data,
    isLoading: !data && !error,
    isError: error,
  };
};
