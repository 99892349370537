import React from "react";
import GenericLayout from "../components/layout/GenericLayout";
import { DateTime } from "luxon";

function ArticleCard({ cover_picture_url, id, name, catchLine, release_date }) {
  return (
    <a href={`/nos-actualites/${id}`} className={"col-span-1"}>
      <div className="parent anim-actualite  shadow-lg rounded-md flex flex-col lg-hcards3 h-cards wide:h-cards-wide ultrawide:h-cards-ultrawide relative mb-20">
        <div className="w-full mx-auto parent rounded-tl-md rounded-tr-md">
          <div
            className="imgs aspect-w-16 aspect-h-11 rounded-tl-md rounded-tr-md bg-center bg-cover  "
            style={{ backgroundImage: `url(${cover_picture_url})` }}
          />
        </div>
        <div className="m-6 flex flex-col">
          <p className="text-14 text-golden-500 mb-2">Actualité</p>
          <p className="text-20 text-black mb-3 font-helvetica font-semibold">
            {name}
          </p>
          <p className="sm:text-base text-16 text-gris font-helvetica font-normal text-overflow  line-clamp-4">
            {catchLine}
          </p>
          <div className="absolute bottom-4 left-6 flex">
            <div className="flex flex-col">
              <p className="text-14 text-gris">
                mis en ligne le{" "}
                {DateTime.fromISO(release_date).toFormat("dd LLL yyyy")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

const News = ({ realisations, categories }) => {
  console.log(realisations.data);

  return (
    <GenericLayout categories={categories}>
      <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="grid grid-rows-1 lg:grid-cols-3 gap-4 ">
            <div className="anim-textactu sm:col-span-1 relative flex flex-col justify-center">
              <div className="flex flex-col justify-center items-center justify-center flex flex-col items-center ">
                <p className="font-birthstone text-rouge text-2xl xl:text-78">
                  Nos
                </p>
                <p className="font-fraunces text-black text-2xl xl:text-56">
                  Actualités
                </p>
              </div>
            </div>
            {realisations.data.map(({ attributes }) => (
              <ArticleCard
                key={attributes.id}
                cover_picture_url={attributes.cover_picture_url}
                id={attributes.id}
                name={attributes.name}
                release_date={attributes.release_date}
                catchLine={attributes.catch_line}
              />
            ))}
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default News;
