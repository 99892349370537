import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import SearchInput from "./search-input/SearchInput";

// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// };
const navigation = [
  { name: "La maison", href: "/la-maison-vasseur", current: true },
  { name: "Notre charcuterie", href: "/nos-gammes-produits", current: false },
  { name: "actualités", href: "/nos-actualites", current: false },
  { name: "contact", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  return (
    <Disclosure as="header" className="bg-white fixed w-full z-50" id="navbar">
      {({ open }) => (
        <>
          <div className="flex flex-col justify-center">
            <div className="shadow-sm w-full">
              <div className="max-w-screen-xl mx-auto grid grid-cols-6 gap-4 pt-4  sm:py-5 px-4">
                <div className="col-start-1 col-end-3 sm:col-end-2 flex justify-start sm:justify-center">
                  <a
                    href="/"
                    className="block min-w-[80px] max-w-[80px] sm:max-w-[150px] h-auto w-full"
                  >
                    <img
                      className="block w-full h-auto"
                      src={logo}
                      alt="logo-vasseur"
                    />
                  </a>
                </div>
                <div className="cols-span-12 sm:col-start-2 sm:col-end-6 hidden sm:flex justify-center h-full">
                  <div className="w-full h-full max-w-screen-md  flex flex-col justify-between">
                    <p className="font-fraunces font-semibold text-16 hidden sm:block text-center lg:text-left">
                      La signature du savoir faire depuis 1960
                    </p>
                    <div className="w-full">
                      <SearchInput />
                    </div>
                  </div>
                </div>
                <div className="col-start-6 col-end-7 lg:hidden flex justify-end items-center">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="rounded-mdtext-gray-400 rounded-lg p-2 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-golden-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="col-start-6 col-end-7 hidden lg:block">
                  <p className="text-golden-500 text-16 inline-block whitespace-nowrap font-helvetica">
                    <a href={"/contact"}>Nous contacter</a>
                    <span>&nbsp;|&nbsp;</span>
                    <a href="tel:0321534265">03 21 53 42 65</a>
                  </p>
                </div>
              </div>
              <div className="w-full px-4 pb-4 block sm:hidden">
                <SearchInput />
              </div>
            </div>

            <nav
              className="hidden lg:py-2 lg:flex lg:space-x-12 lg:max-w-3xl lg:mx-auto"
              aria-label="Global"
            >
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.href === pathname
                      ? "text-golden-500 underline"
                      : "text-golden-900 hover:bg-skin-100 hover:text-golden-800",
                    "rounded-md py-2 inline-flex items-center text-lg uppercase font-medium font-fraunces whitespace-nowrap first-letter:uppercase"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
          <Disclosure.Panel
            as="nav"
            className={"lg:hidden h-screen backdrop-blur-sm bg-white/30"}
            aria-label="Global"
          >
            <div className="pt-2 pb-3 px-2 space-y-1 bg-white">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.href === pathname
                      ? "bg-gray-100 text-golden-900"
                      : "text-golden-900",
                    "block rounded-md py-2 px-3 text-28  font-fraunces first-letter:uppercase"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
