import React, { useContext, useEffect, useState } from "react";
import CategoryCard from "../components/CategorieCard";
import GenericLayout from "../components/layout/GenericLayout";
import hero from "../../../assets/images/charcuterie_hero.jpg";
import CategoriesContext from "../contexts/CategoriesContext";
import cn from "classnames";

const Categories = ({ categories }) => {
  const { updateFilteredCategories } = useContext(CategoriesContext);
  const [showImageTop, setShowImageTop] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(new URL(window.location).search);
    const query = params.get("recherche");
    if (query) {
      updateFilteredCategories(categories);
      if (categories && categories.data.length) {
        setShowImageTop(false);
      }
    }
  }, []);

  return (
    <div className="sm:w-vw-5/6 sm:mx-auto mx-4">
      <div
        className={cn(
          "relative aspect-w-16 aspect-h-5 rounded-lg bg-cover bg-center",
          { hidden: !showImageTop }
        )}
        style={{ backgroundImage: `url(${hero})` }}
      />
      <div className={"flex flex-col space-y-12"}>
        <div
          className={cn("flex justify-between xl:flex-row flex-col", {
            "xl:mt-20 mt-8": showImageTop,
          })}
        >
          <div className="xl:w-vw-1/4 flex flex-col sm:text-center sm:mb-4 xl:mb-0">
            <p className="font-birthstone sm:text-56 text-32 text-rouge z-10">
              Notre
            </p>
            <p className="font-fraunces sm:text-56 text-32 text-black -mt-4 z-10">
              Charcuterie
            </p>
          </div>

          <p className="font-helvetica sm:text-20 text-16 text-black text-justify z-10 xl:w-paragraph">
            Fondée en 1960, la charcuterie Vasseur est une entreprise familiale
            qui se transmet de génération en génération. Nous produisons des
            jambons, saucissons, pâtés et autres spéciliatés polonaises. Nous
            mettons un point d'honneur à respecter les normes les plus élevées
            en matière de qualité et d'hygiène.
          </p>
        </div>
        <div
          id="categories"
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 -mt-32 gap-8 max-w-full"
        >
          {categories.data.map((category, key) => (
            <CategoryCard category={category} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ProvidedCategoriesPage = ({ categories }) => {
  return (
    <GenericLayout categories={categories}>
      <Categories categories={categories} />
    </GenericLayout>
  );
};

export default ProvidedCategoriesPage;
