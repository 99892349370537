import React from "react";
import GenericLayout from "../components/layout/GenericLayout";
import home from "../../../assets/images/homepage.jpg";
import andouille from "../../../assets/images/andouille_home.jpg";
import factory from "../../../assets/images/factory.jpg";
import famille from "../../../assets/images/famille.jpg";
import planche from "../../../assets/images/planche.jpg";
import SectionProduct from "../components/SectionProduct";
import NewsSection from "../components/NewsSection";

const Homepage = ({ categories }) => {
  return (
    <GenericLayout categories={categories}>
      <div className="xl:w-vw-2/3 lg:mx-auto sm:mx-32">
        <div className="flex xl:flex-row flex-col-reverse items-center justify-between">
          <div className="xl:w-vw-1/3 sm:space-x-4 sm:space-y-4 space-y-2 justify-center flex flex-col opacity-cards">
            <div className="flex items-end justify-end sm:space-x-4 space-y-4 sm:mx-0 mx-4">
              <div className="sm:mr-0 mr-2 img-radius">
                <div
                  className="imgs img-radius aspect-w-4 aspect-h-3 w-vw-29 xl:w-vw-10 rounded-lg bg-contain"
                  style={{ backgroundImage: `url(${andouille})` }}
                />
              </div>

              <div className="aspect-w-3 aspect-h-2 w-vw-59 xl:w-vw-21 img-radius">
                <div
                  className="imgs img-radius rounded-lg bg-contain"
                  style={{ backgroundImage: `url(${famille})` }}
                />
              </div>
            </div>
            <div className="flex justify-end sm:space-x-4 sm:mx-0 mx-4">
              <div className="sm:mr-0 mr-2 img-radius">
                <div
                  className="imgs img-radius aspect-w-5 aspect-h-4 w-vw-1/2 xl:w-vw-18 rounded-lg bg-contain"
                  style={{ backgroundImage: `url(${factory})` }}
                />
              </div>

              <div className="aspect-w-9 aspect-h-5 w-vw-29 xl:w-vw-10 img-radius">
                <div
                  className="imgs img-radius rounded-lg bg-contain"
                  style={{ backgroundImage: `url(${planche})` }}
                />
              </div>
            </div>
          </div>

          <div className="xl:w-vw-1/4 flex flex-col justify-center sm:mx-0 mx-4">
            <p className="font-fraunces sm:text-72 text-56 text-black line-move">
              Une
            </p>
            <p className="font-birthstone sm:text-72 text-56 text-rouge line-move-italic">
              Entreprise
            </p>
            <p className="font-fraunces sm:text-72 text-56 text-black line-move">
              innovante
            </p>
            <p className="font-helvetica font-normal 2xl:text-20 text-16 text-black sm:mt-10 mt-4 paragraph-move max-w-prose inline-block ">
              <span className={"2xl:block"}>
                Bienvenue sur le site de la Charcuterie Vasseur,&nbsp;
              </span>
              <span className={"2xl:block"}>
                une entreprise française familiale spécialisée dans la&nbsp;
              </span>
              <span className={"2xl:block"}>
                fabrication de produits charcutiers haut de gamme.
              </span>
            </p>
            <a
              href={"/la-maison-vasseur"}
              className="paragraph-move button-hover flex sm:w-52 w-36 font-helvetica sm:text-20 text-16 justify-center text-white bg-rouge sm:mt-10 mt-8 mb-12 sm:py-4 sm:px-6 p-4 rounded-md shadow items-center"
            >
              En savoir plus
            </a>
          </div>
        </div>
      </div>
      <hr className="sm:w-vw-1/4 sm:ml-hr-left sm:mr-hr-right sm:mt-28 sm:mb-20 my-12 mx-24 border border-golden-500" />
      <div className={"mx-auto"}>
        <SectionProduct categories={categories} />
      </div>
      <div className={"mx-auto max-w-7xl"}>
        <NewsSection />
      </div>
    </GenericLayout>
  );
};

export default Homepage;
